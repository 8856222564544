<template>
  <v-container>
    <v-card class="mx-auto my-4">
      <v-card-title>
        <v-img
          v-if="token.logo"
          :src="token.logo.replace('64x64', '128x128')"
          max-width="60px"
          max-height="60px"
          class="float-left mr-4"
          contain
          eager
        ></v-img>
        <span class="heading-2 mr-4">
          {{ token.name }}
        </span>
        <span class="subtitle-1 pt-1 grey--text mr-4">
          {{ token.symbol }}
        </span>
        <v-btn
          class="mr-4"
          :color="token.reward > 0 ? 'green' : 'lime'"
          rounded
          outlined
          small
          :to="'/review/' + token.slug"
        >
          <span v-if="token.reward > 0">
            Review and Earn <strong>{{ token.reward }}</strong> VIEW
          </span>
          <span v-if="token.reward == 0">
            Review Now
          </span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-rating
          v-model="token.rating"
          color="yellow darken-2"
          background-color="blue-grey lighten-3"
          class="float-left mr-4"
          half-increments
          readonly
        ></v-rating>
        <v-btn fab elevation="0">
          {{ token.rating | toNumber }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-card outlined min-height="120px">
              <v-card-title>
                Overview
              </v-card-title>
              <p class="metric px-4 py-2 ma-0">
                <span class="label">Tags:</span>
                <v-chip
                  v-for="p in token.tags"
                  :key="p"
                  color="blue lighten-5"
                  class="mr-2"
                  rounded
                  small
                >
                  {{ p }}
                </v-chip>
              </p>
              <p class="metric px-4 py-2 ma-0">
                <span class="label">Platforms:</span>
                <v-btn
                  v-if="token.category == 'coin'"
                  color="blue lighten-4"
                  class="text-none mr-2"
                  elevation="0"
                  rounded
                  x-small
                >
                  Native
                </v-btn>
                <v-btn
                  v-for="p in token.platforms"
                  :key="p.chain_id"
                  :color="$store.getters.platformFor(p.chain_id)"
                  class="text-none mr-2"
                  elevation="0"
                  :href="$store.getters.explorerUrlFor(p.chain_id) + '/token/' + p.address"
                  target="_blank"
                  rounded
                  x-small
                >
                  {{ $store.getters.platformName(p.chain_id) }}
                </v-btn>
              </p>
              <p class="metric px-4 py-2 ma-0">
                <span class="label">Total Supply:</span>
                <span>{{ token.total_supply | toNumber }}</span>
              </p>
              <p class="metric px-4 py-2 ma-0">
                <span class="label">Circulating Supply:</span>
                <span>{{ token.circulating_supply | toNumber }}</span>
              </p>
              <p class="metric px-4 py-2 ma-0">
                <span class="label">Rank:</span>
                <span>{{ token.rank | toNumber }}</span>
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card outlined min-height="120px">
              <v-card-title>
                <span>Ratings</span>
                <span class="subtitle-1 grey--text pl-2">
                  ({{ reviews.length | toNumber }})
                </span>
              </v-card-title>
              <p class="metric px-4 py-3 ma-0">
                <v-sparkline
                  :gradient="['#2196F3', '#3F51B5']"
                  line-width="20"
                  :value="histogram"
                  :labels="histogramLabels"
                  label-size="6"
                  height="63"
                  type="bar"
                ></v-sparkline>
              </p>
              <p class="metric px-4 py-2 ma-0">
                <span class="label">Scam reports:</span>
                <v-btn fab x-small elevation="0">
                  {{ (token.scam_count || 0) | toNumber }}
                </v-btn>
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card outlined min-height="120px">
              <v-card-title>
                <span>Reviews</span>
                <span class="subtitle-1 grey--text pl-2">
                  ({{ reviews.length | toNumber }})
                </span>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="reviews"
                :items-per-page="10"
                :loading="loadingReviews"
                :search="search"
                no-data-text="No reviews yet"
                loading-text="Loading reviews..."
                no-results-text="No reviews match your search"
                class="reviews"
                hide-default-header
              >
                <template #[`item.rating`]="{item}">
                  <v-rating
                    v-model="item.rating"
                    color="yellow darken-2"
                    background-color="blue-grey lighten-3"
                    class="ms-n2"
                    half-increments
                    readonly
                    small
                  ></v-rating>
                  <p class="text-caption grey--text my-0">
                    {{ item.date.replace(/T/, ' ').replace(/\..+/, '') }}
                  </p>
                  <p class="text-caption grey--text my-0">
                    <v-avatar size="12">
                      <img :src="require(`@/assets/${$store.getters.platformFor(item.chain_id)}.svg`)" alt="Ethereum">
                    </v-avatar>
                    <a
                      v-if="item.signature"
                      class="text-caption text-decoration-none my-0"
                      :href="$store.getters.explorerUrlFor(item.chain_id) + '/address/' + item.user_address"
                      target="_blank"
                    >
                      Account
                    </a>
                    <span v-if="item.signature && item.transaction">•</span>
                    <a
                      v-if="item.transaction"
                      class="text-caption text-decoration-none my-0"
                      :href="$store.getters.explorerUrlFor(item.chain_id) + '/tx/' + item.transaction"
                      target="_blank"
                    >
                      Transaction
                    </a>
                  </p>
                </template>
                <template #[`item.review`]="{item}">
                  <p>
                    {{ item.review }}
                  </p>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.token.name ? `${this.token.name} (${this.token.symbol})` : 'Token',
      titleTemplate: '%s Info and Reviews | Metaview',
    }
  },
  data: () => ({
    token: {},
    tokens: [],
    loading: false,
    loadingReviews: false,
    search: '',
    headers: [
      { text: 'Rating', value: 'rating', align: 'left rating' },
      { text: 'Review', value: 'review', align: 'left review' },
    ],
    reviews: [],
    histogram: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    histogramLabels: [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
    rating: {},
  }),
  mounted() {
    this.getToken()
  },
  methods: {
    getToken() {
      this.loading = true
      this.$store.dispatch('getToken', this.$route.params.slug).then((token) => {
        this.token = token
        this.reviews = token.reviews ? token.reviews.reverse() : this.reviews
        this.histogram = token.histogram ? token.histogram : this.histogram
        this.loading = false
        if(!token.reviews && token.review_count > 0) {
          this.getTokenReviews()
        }
      }).catch(() => {
        this.loading = false
      })
    },
    getTokenReviews() {
      this.loadingReviews = true
      this.$store.dispatch('getTokenReviews', this.$route.params.slug).then((token) => {
        this.token = token
        this.reviews = token.reviews ? token.reviews.reverse() : this.reviews
        this.histogram = token.histogram ? token.histogram : this.histogram
        this.loadingReviews = false
      }).catch(() => {
        this.loadingReviews = false
      })
    }
  },
}
</script>

<style scoped>
.metric {
  border-top: 1px solid;
  border-color: #f5f5f5;
}
.label {
  width: 150px;
  display: inline-block;
}
.reviews {
  border-top: 1px solid;
  border-color: #ddd;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.reviews >>> .rating {
  width: 180px;
}
</style>
